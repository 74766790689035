import loadable from "@loadable/component"
import axios from "axios"
import { Link, navigate } from "gatsby"
import * as qs from "query-string"
import React, {useEffect, useRef, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"
import AsyncSelect from "react-select/async"
import FormFields from "../../../static/forms/book_appointment.json"
import BathroomList from "../../search_config/PMbathrooms.json"
import BedroomList from "../../search_config/PMbedrooms.json"
import ViewsList from "../../search_config/views.json"
import { formTracking } from "../Common/utils"
import { filterCustomStyles } from "../SelectDropdownStyle/SelectDropdownStyle"
import Stepper from "../Stepper/Stepper"
import DefaultForm from "../forms/default-form-module"
import InfoIcon from "../icons/InfoIcon"
import { postFormData } from "./../forms/api/Api"
import ValuationResult from "./ValuationResult"
import ReCaptchaBox from "../../components/forms/elements/recaptcha"
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import "./assets/styles/_index.scss"

const Select = loadable(() => import("react-select"))
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`,
}
const PropertyMonitor = props => {
  const myRef = useRef(null)
  const executeScroll = () => {
    myRef?.current?.scrollIntoView({ behavior: "smooth" })
  }
  const [step, setStep] = useState(0)
  const [loading, setloading] = useState(false)
  const [phone, setPhone] = useState()
  const [phoneLength, setPhoneLength] = useState(0);
  const [phoneError, setPhoneError] = useState(false)
  const [stepOneValidate, setStepOneValidate] = useState(false)
  const [stepTwoValidate, setStepTwoValidate] = useState(false)
  const [stepThreeValidate, setStepThreeValidate] = useState(false)
  const [propertyType, setPropertyType] = useState(1)
  const [propertyTypeError, setPropertyTypeError] = useState(true)
  const [name, setName] = useState("")
  const [nameError, setNameError] = useState(true)
  const [otp, setOtp] = useState("")
  const [otpError, setOtpError] = useState(true)
  const [otpOn, setOtpOn] = useState(false)
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(true)
  const [emailFormatError, setEmailFormatError] = useState(true)
  const [locationInputValue, setLocationInputValue] = useState("")
  const [locationValue, setLocationValue] = useState({})
  const [locationError, setLocationError] = useState(true)
  const [unitInputData, setUnitInputData] = useState("")
  const [unitDefaultData, setUnitDefaultData] = useState([])
  const [unit, setUnit] = useState({})
  const [unitSize, setUnitSize] = useState("")
  const [unitSizeError, setUnitSizeError] = useState(true)
  const [unitError, setUnitError] = useState(true)
  const [bed, setBed] = useState(null)
  const [bedError, setBedError] = useState(true)
  const [bath, setBath] = useState(null)
  const [floor, setFloor] = useState("")
  const [checked, setChecked] = useState([])
  const [upgradeBath, setUpgradeBath] = useState(0)
  const [upgradeKitchen, setUpgradeKitchen] = useState(0)
  const [upgradeFlooring, setUpgradeFlooring] = useState(0)
  const [upgradePool, setUpgradePool] = useState(0)
  const [upgradeGarden, setUpgradeGarden] = useState(0)
  const [valuation, setvaluation] = useState(0)

  const [token, setToken] = useState("")


  const [show, setShow] = useState(false)

  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  const tryNew = () => {
    window.scroll(0, 0)
    setStep(0)
    setLocationValue({})
    setUnit({})
    setUnitInputData("")
    unitloadOptions()
    setConfidenceLevel("")
    setConfidenceMessage("")
    navigate(`/property-valuation/instant-valuation/`)
  }
  const stepOne = () => {
    if (unitError || locationError) {
      setStepOneValidate(true)
      // if (locationValue?.id) {
      //     setLocationError(false)
      // }
      // if (unit?.id) {
      //     setLocationError(false)
      // }
      executeScroll()
    } else {
      window.scroll(0, 0)
      setStepOneValidate(false)
      formTracking({
        event_tracking: "instant-valuation",
        form_name: "Instant online - property valuation step 1",
      })
      setStep(step + 1)
    }
  }
  const [reportHash, setreportHash] = useState("")
  const [confidenceMessage, setConfidenceMessage] = useState("")
  const [confidenceLevel, setConfidenceLevel] = useState("")

  const recaptchaRef = React.createRef()


  const stepTwo = () => {
    if (propertyTypeError || bedError || unitSizeError) {
      setStepTwoValidate(true)
      executeScroll()
      // if (propertyType) {
      //     setPropertyTypeError(false)
      // }
      // if (bed) {
      //     setBedError(false)
      // }
      // if (unitSize) {
      //     setUnitSizeError(false)
      // }
    } else {
      setloading(true)

      let data = JSON.stringify({
        unitSizeSqft: unitSize,
        plotSizeSqft: unit.plot_size_sqft,
        propertyTypeId: propertyType,
        locationId: locationValue?.location_id,
        bedrooms: bed,
        bathrooms: bath,
        floor: floor,
        unitNumber: unit.unit_number,
        views: checked?.toString(),
        upgrades: `1|${upgradeBath ? upgradeBath : 0},3|${
          upgradeKitchen ? upgradeKitchen : 0
        },2|${upgradeFlooring ? upgradeFlooring : 0},5|${
          upgradePool ? upgradePool : 0
        },4|${upgradeGarden ? upgradeGarden : 0}`,
        developerName: unit.developer_name,
        parking: unit.parking,
        exec: "",
        email: "",
      })

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.GATSBY_STRAPI_SRC}/api/avm/submit`,
        headers: headers,
        data: data,
      }

      axios
        .request(config)
        .then(response => {
          // console.log(response.data)
          if (response.status === 200) {
            if (response.data?.confidenceLevel === "N/A") {
              window.scroll(0, 0)
            } else {
              window.scroll(0, 0)
            }
            setStepTwoValidate(false)
            setloading(false)
            setConfidenceMessage(response.data?.confidenceMessage)
            setConfidenceLevel(response.data?.confidenceLevel)
            setreportHash(response.data.reportHash)
            setStep(response.data?.confidenceLevel === "N/A" ? step : step + 1)
            formTracking({
              event_tracking: "instant-valuation",
              form_name: "Instant online - property valuation step 2",
            })
          }
        })
        .catch(error => {
          setloading(false)
          // console.log(error);
        })
    }
  }

  useEffect(()=>{
    if (token !== "") {
      axios
        .post(
          `${process.env.GATSBY_STRAPI_SRC}/api/avm/getreport`,
          {
            data: {
              reportHash: reportHash,
              otpCode: otp,
            },
          },
          {
            headers: headers,
            data: {
              reportHash: reportHash,
              otpCode: otp,
            },
          }
        )
        .then(response => {
          if (response.status === 200) {
            if (response.data.errors) {
              setOtpError(response.data.errors)
            } else {
              var postData = {}
              // postData.name = name
              // postData.telephone = phone
              postData["g-recaptcha-response"] = token
              postData["form_name"] = "Instant Online Valuation Request"
              postData["first_name"] = name
              postData["name"] = name
              postData["to_email_id"] =
                "ce@hausandhaus.com, webinar@hausandhaus.integratedinterest.email, hauswebsiteleads@gmail.com"
              postData["phone"] = phone
              postData["telephone"] = phone
              postData["location_name"] = locationValue?.name?locationValue?.name:""
              postData["unit_number"] = unit?.unit_number?unit.unit_number:""
              postData["email"] = email
              postData["source_url"] = pageurl
              postData["report_url"] = response?.data?.reportUrl
              postData["final_valuation"] =
                "AED" + " " + response?.data?.finalValuation?.toLocaleString()
              postData[
                "valuation_range"
              ] = `AED  ${response?.data?.finalValuationLowerEnd?.toLocaleString()} - AED ${response?.data?.finalValuationHighEnd?.toLocaleString()}`
              postData["email_temp_user"] = "instant_valuation_user"
              postData["email_temp_admin"] = "instant_valuation_admin"
              postData["email_subject_user"] = "haus & haus - Instant Valuation"
              postData["email_subject_admin"] =
                "haus & haus - Instant Valuation"
              // postData.formname = "Instant Online Valuation Request"
              postFormData(postData).then(async apiRes => {
                // GTM tracking event
                // gtmEvent({ formType: 'Property Valuation', formName: 'Instant Online Valuation Request' })
                // lets send mail

                await window.fetch(
                  `${process.env.GATSBY_CLOUD_URL}/api/functions`,
                  {
                    method: `POST`,
                    mode: "no-cors",
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: qs.stringify(postData),
                  }
                )
              })
              setvaluation(response.data)
              window.scroll(0, 0)
              setStepThreeValidate(false)
              setStep(step + 1)
              formTracking({
                event_tracking: "instant-valuation",
                form_name: "Instant online - property valuation step 3",
              })
            }
          }
        })
        .catch(function (error) {
          console.log("response error =>", error)
        })
    }
  },[token])

  const stepThree = () => {
    if(phoneLength<7){
      setPhoneError(true)
    }
    else{
      setPhoneError(false)
    }
    if (
      emailError ||
      nameError ||
      emailFormatError ||
      phoneError ||
      !otpOn ||
      otpError
    ) {
      executeScroll()
      setStepThreeValidate(true)
      // if (name) {
      //     setNameError(false)
      // }
      // if (email) {
      //     setEmailError(false)
      // }
    } else {
      recaptchaRef?.current?.execute();
    }
  }

  // Add/Remove checked item from list
  const handleCheck = event => {
    var updatedList = [...checked]
    if (event.target.checked) {
      updatedList = [...checked, event.target.value]
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1)
    }
    setChecked(updatedList)
  }
  const generateOtp = () => {
    setloading(true)
    setOtpOn(true)
    let data = JSON.stringify({
      name: name,
      email: email,
      phone: phone,
      reportHash: reportHash,
    })

    let config = {
      method: "post",
      url: `${process.env.GATSBY_STRAPI_SRC}/api/avm/generateotp`,
      headers: headers,
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        // console.log(response.data)
        if (response.status === 200) {
          setloading(false)
        }
      })
      .catch(error => {
        setloading(false)
        // console.log(error);
      })
  }

  // Generate string of checked items
  const checkedItems = checked.length
    ? checked.reduce((total, item) => {
        return total + ", " + item
      })
    : ""

  // Return classes based on whether item is checked
  var isChecked = item =>
    checked.includes(item) ? "checked-item" : "not-checked-item"

  // handle input change event
  const locationHandleInputChange = value => {
    setLocationInputValue(value)
    setConfidenceLevel("")
    setConfidenceMessage("")
  }

  // handle selection
  const locationHandleChange = value => {
    setLocationValue(value)
    setUnit({})
    setUnitInputData("")
    setLocationError(false)
    setStep(0)
    setUnitError(true)
    loadOptions()
    unitloadOptions()
    setConfidenceLevel("")
    setConfidenceMessage("")
    // setUnitDefaultData()
  }

  // handle input change event
  const unitHandleInputChange = value => {
    setUnitInputData(value)
    setConfidenceLevel("")
    setConfidenceMessage("")
  }

  // handle selection
  const unitHandleChange = value => {
    setUnitError(false)
    setUnit(value)
    setPropertyType(value.property_type_id)
    setPropertyTypeError(value.property_type_id ? false : true)
    setBed(value.bedrooms)
    setBedError(value.bedrooms ? false : true)
    setBath(value.bathrooms)
    setUnitSize(value.built_up_area_sqft)
    setUnitSizeError(value.built_up_area_sqft ? false : true)
    setFloor(value.floor)
    setConfidenceLevel("")
    setConfidenceMessage("")
  }

  const onBedSelect = value => {
    setBed(value.value)
  }
  const onBathSelect = value => {
    setBath(value.value)
  }

  // load options using API call
  const loadOptions = inputValue => {
    // let data = new FormData();
    //     data.append('q', inputValue);
    // console.log(data)
    return axios
      .post(
        `${process.env.GATSBY_STRAPI_SRC}/api/avm/location`,
        {
          data: {
            q: inputValue,
          },
        },
        {
          headers: headers,
        }
      )
      .then(response => {
        return response.data
      })
      .catch(function (error) {
        // console.log("response error =>", error);
      })
  }

  const unitloadOptions = inputValue => {
    return axios
      .post(
        `${process.env.GATSBY_STRAPI_SRC}/api/avm/unit`,
        {
          data: {
            q: inputValue,
            locationId: locationValue?.location_id
              ? locationValue?.location_id
              : 20,
          },
        },
        {
          headers: headers,
        }
      )
      .then(response => response.data)
      .catch(function (error) {
        // console.log("response error =>", error);
      })
  }
  const handlechange = event => {
    // remove initial empty spaces
    if (event.target.name === "unitsize") {
      setUnitSize(event.target.value.trimStart())
      setUnitSizeError(event.target.value ? false : true)
    } else if (event.target.name === "floor") {
      setFloor(event.target.value.trimStart())
    } else if (event.target.name === "name") {
      setName(event.target.value.trimStart())
      setNameError(event.target.value ? false : true)
    } else if (event.target.name === "otp") {
      setOtp(event.target.value.trimStart())
      setOtpError(event.target.value ? false : true)
    } else if (event.target.name === "email") {
      setEmail(event.target.value.trimStart())
      setEmailError(event.target.value ? false : true)
      setEmailFormatError(
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          event.target.value
        )
          ? true
          : false
      )
    }
    event.target.value = event.target.value.trimStart()
  }

  const phoneChange = value => {
    setPhone(value)
    // setPhoneError(false)
    let countValue = value;
    if(countValue) {
      const phoneNumber = parsePhoneNumberFromString(countValue?.replace(/ /g,""));
      if (phoneNumber) {
        setPhoneLength(phoneNumber.nationalNumber.length);
      } else {
        setPhoneLength(0);
      }
    } else {
      setPhoneLength(0);
    }
  }

  useEffect(()=>{
    if(phoneLength>=7) {
      setPhoneError(false)
    }
    else{
      setPhoneError(true)
    }
  },[phoneLength])

  const onChangeValue = e => {
    if (e.target.name === "upgrade-bath") {
      setUpgradeBath(parseInt(e.target.value))
    }
    if (e.target.name === "upgrade-kitchen") {
      setUpgradeKitchen(parseInt(e.target.value))
    }
    if (e.target.name === "upgrade-flooring") {
      setUpgradeFlooring(parseInt(e.target.value))
    }
    if (e.target.name === "upgrade-pool") {
      setUpgradePool(parseInt(e.target.value))
    }
    if (e.target.name === "upgrade-garden") {
      setUpgradeGarden(parseInt(e.target.value))
    }
  }

  const handleModal = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleonVerify = token => {
    console.log("captcha verified")
    setToken(token)
  }

  return (
    <div className="property-monitor">
      {loading && (
        <div className="loader">
          <div className="loader-icon-slider" />
        </div>
      )}
      {step === 0 || step === 1 || step === 2 || step === 3 ? (
        <div>
          <h1>
            {step === 0
              ? "Get your instant property valuation"
              : step === 1
              ? "Get your instant property valuation"
              : step === 2
              ? "Get your instant property valuation"
              : "Your full report is here."}
          </h1>
          <p>
            {step === 0
              ? "Get an approximate valuation in a matter of minutes."
              : step === 1
              ? "Get an approximate valuation in a matter of minutes."
              : step === 2
              ? "Get an approximate valuation in a matter of minutes."
              : "Here's your property valuation based on the information you gave us. You can download the full report below and you'll also receive it by email shortly (don't forget to check your spam)."}
          </p>
          {step <= 2 && <Stepper step={step} />}
          {(step === 0 && confidenceLevel !== "N/A") ||
          (step === 1 && confidenceLevel !== "N/A") ||
          (step === 2 && confidenceLevel !== "N/A") ||
          (step === 3 && confidenceLevel !== "N/A") ? (
            <div>
              <div className="title-left-bk">
                <div ref={myRef}></div>
                <h6>
                  {step === 0
                    ? "Find your property"
                    : step === 1
                    ? "Confirm your property details"
                    : step === 2
                    ? "Provide your details"
                    : ""}
                </h6>
                <p className="pre-text">
                  {step === 0
                    ? "In 3 easy steps, you can get an instant property valuation powered by Property Monitor. We will also send you a six page detailed report via email, including recent property sales, live listings and Dubai market trends."
                    : step === 1
                    ? "We found your address in the ValUd system, please confirm the details are correct. If your property has had an upgrade, renovation or extension, let us know so we can include it in the valuation."
                    : step === 2
                    ? "You’re almost done – please enter your details below as a last step. Once completed, we will provide your property valuation instantly and send the full report to your email address."
                    : ""}
                </p>
              </div>
              {(step === 0 && confidenceLevel !== "N/A") ||
              (step === 1 && confidenceLevel !== "N/A") ? (
                <>
                  <h4>Search by Community/Tower name</h4>
                  <div
                    className={`${
                      locationError && stepOneValidate
                        ? "error dropdown-select"
                        : "dropdown-select"
                    }`}
                  >
                    <AsyncSelect
                      placeholder={`Example: "Princess Tower, Dubai Marina" or "Springs 4, The Springs"`}
                      className={"select-control"}
                      classNamePrefix={"react-select"}
                      // cacheOptions
                      defaultOptions
                      getOptionLabel={e => e.name}
                      getOptionValue={e => e.id}
                      loadOptions={loadOptions}
                      onInputChange={locationHandleInputChange}
                      onChange={locationHandleChange}
                      styles={filterCustomStyles}
                    />
                    {locationError && stepOneValidate && (
                      <div className="errortxt">
                        Please select Community/Tower name
                      </div>
                    )}
                  </div>
                  <div
                    className={`${
                      unitError && stepOneValidate
                        ? "error dropdown-select"
                        : "dropdown-select"
                    }`}
                  >
                    <AsyncSelect
                      placeholder={`Enter the Unit Number`}
                      className={"select-control"}
                      classNamePrefix={"react-select"}
                      styles={filterCustomStyles}
                      // cacheOptions
                      defaultOptions
                      getOptionLabel={e => e.unit_number}
                      getOptionValue={e => e.id}
                      loadOptions={unitloadOptions}
                      onInputChange={unitHandleInputChange}
                      onChange={unitHandleChange}
                      isDisabled={locationValue?.name ? false : true}
                    />
                    {unitError && stepOneValidate && (
                      <div className="errortxt">Please select Unit Number</div>
                    )}
                  </div>
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : (
        ""
      )}

      {step === 0 && confidenceLevel !== "N/A" ? (
        <Button
          onClick={() => stepOne()}
          className="button button-filled-green"
        >
          next step
        </Button>
      ) : step === 1 && confidenceLevel !== "N/A" ? (
        <div>
          <h4>Property Type *</h4>
          <div className="d-flex btn-bk justify-content-between">
            <Button
              onClick={() => setPropertyType(1)}
              className={
                propertyType === 1
                  ? "active button button-outline-black"
                  : "button button-outline-black"
              }
            >
              Apartment
            </Button>
            <Button
              onClick={() => setPropertyType(8)}
              className={
                propertyType === 8
                  ? "active button button-outline-black"
                  : "button button-outline-black"
              }
            >
              Villa
            </Button>
            <Button
              onClick={() => setPropertyType(10)}
              className={
                propertyType === 10
                  ? "active button button-outline-black"
                  : "button button-outline-black"
              }
            >
              Townhouse
            </Button>
          </div>
          {propertyTypeError && stepTwoValidate && (
            <div className="errortxt">Please select Property Type</div>
          )}
          <h4>Number of Bedrooms *</h4>
          <div
            className={`${
              bedError && stepTwoValidate
                ? "error dropdown-select"
                : "dropdown-select"
            }`}
          >
            <Select
              options={BedroomList}
              onChange={e => onBedSelect(e)}
              value={BedroomList.filter(function (option) {
                return option.label === bed
              })}
              name={"bedroom"}
              placeholder={``}
              className={"select-control"}
              classNamePrefix={"react-select"}
              styles={filterCustomStyles}
              isSearchable={false}
            />
          </div>
          {bedError && stepTwoValidate && (
            <div className="errortxt">Please select Bedrooms</div>
          )}
          <h4>Number of Bathrooms</h4>
          <div className={`dropdown-select`}>
            <Select
              options={BathroomList}
              onChange={e => onBathSelect(e)}
              value={BathroomList.filter(function (option) {
                return option.label === bath
              })}
              name={"bedroom"}
              placeholder={``}
              className={"select-control"}
              classNamePrefix={"react-select"}
              styles={filterCustomStyles}
              isSearchable={false}
            />
          </div>
          <h4>Unit Size (sq ft) *</h4>
          <Form.Control
            required={true}
            type={"text"}
            name={"unitsize"}
            value={unitSize}
            placeholder={"Enter the built up area of the property"}
            onChange={handlechange}
            autocomplete="off"
            className={unitSizeError && stepTwoValidate ? "error" : ""}
          />
          {unitSizeError && stepTwoValidate && (
            <div className="errortxt">Please Enter Unit Size</div>
          )}
          <h4>Floor</h4>
          <Form.Control
            required={false}
            type={"text"}
            name={"floor"}
            value={floor}
            placeholder={"Enter the floor level the unit is on"}
            onChange={handlechange}
            autocomplete="off"
          />
          <Accordion defaultActiveKey="">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Select Views
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={props => (
                    <Tooltip {...props}>
                      Not all views are created equal, some may add value,
                      others may detract, and some may have no bearing on value
                      at all. Select the main direct and prominent views that
                      the property has
                    </Tooltip>
                  )}
                >
                  <Button className="info-btn">
                    <InfoIcon />
                  </Button>
                </OverlayTrigger>
              </Accordion.Header>
              <Accordion.Body>
                <div className="view-list">
                  {ViewsList?.map((item, i) => {
                    return item.viewId === 0 ||
                      (item.viewId === 12 && propertyType === 8) ||
                      (item.viewId === 12 && propertyType === 10) ||
                      (item.viewId === 1 && propertyType === 1) ? (
                      <div
                        className={`view-item ${item.viewName
                          ?.toLowerCase()
                          ?.replace(/ /g, "")} ${isChecked(item.viewName)}`}
                      >
                        <div key={i}>
                          <input
                            value={item.viewName}
                            type="checkbox"
                            onChange={handleCheck}
                          />
                          <span className={isChecked(item.viewName)}>
                            {item.viewName}
                          </span>
                        </div>
                      </div>
                    ) : null
                  })}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey="">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Select Upgrades{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={props => (
                    <Tooltip {...props}>
                      Upgrades can have a significant bearing on property values
                      and determining such can be largely subjective. For
                      simplicity the AVM will apply a tuning algorithm only for
                      partial and full upgrades based on the typical update
                      quality for similar properties in the area. Select if the
                      property has had any upgrades completed since handover.
                    </Tooltip>
                  )}
                >
                  <Button className="info-btn">
                    <InfoIcon />
                  </Button>
                </OverlayTrigger>
              </Accordion.Header>
              <Accordion.Body>
                <div className="radio-bk">
                  <div className="icon-bk bath"></div>
                  <div className="option-bk">
                    <p className="title">Bathroom:</p>
                    <div className="d-md-flex d-block">
                      <label className="radio-item">
                        <input
                          onChange={e => onChangeValue(e)}
                          checked={upgradeBath === 1}
                          type="radio"
                          value="1"
                          name="upgrade-bath"
                        />{" "}
                        <span>Partially Upgraded</span>
                      </label>
                      <label className="radio-item">
                        <input
                          onChange={e => onChangeValue(e)}
                          checked={upgradeBath === 2}
                          type="radio"
                          value="2"
                          name="upgrade-bath"
                        />{" "}
                        <span>Full Upgraded</span>
                      </label>
                      <label className="radio-item">
                        <input
                          onChange={e => onChangeValue(e)}
                          checked={upgradeBath === 0}
                          type="radio"
                          value="0"
                          name="upgrade-bath"
                        />{" "}
                        <span>None</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="radio-bk">
                  <div className="icon-bk kitchen"></div>
                  <div className="option-bk">
                    <p className="title">Kitchen:</p>

                    <div className="d-md-flex d-block">
                      <label className="radio-item">
                        <input
                          onChange={e => onChangeValue(e)}
                          checked={upgradeKitchen === 1}
                          type="radio"
                          value="1"
                          name="upgrade-kitchen"
                        />{" "}
                        <span>Partially Upgraded</span>
                      </label>
                      <label className="radio-item">
                        <input
                          onChange={e => onChangeValue(e)}
                          checked={upgradeKitchen === 2}
                          type="radio"
                          value="2"
                          name="upgrade-kitchen"
                        />{" "}
                        <span>Full Upgraded</span>
                      </label>
                      <label className="radio-item">
                        <input
                          onChange={e => onChangeValue(e)}
                          checked={upgradeKitchen === 0}
                          type="radio"
                          value="0"
                          name="upgrade-kitchen"
                        />{" "}
                        <span>None</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="radio-bk">
                  {" "}
                  <div className="icon-bk floor"></div>
                  <div className="option-bk">
                    <p className="title">Flooring:</p>

                    <div className="d-md-flex d-block">
                      <label className="radio-item">
                        <input
                          onChange={e => onChangeValue(e)}
                          type="radio"
                          checked={upgradeFlooring === 1}
                          value="1"
                          name="upgrade-flooring"
                        />{" "}
                        <span>Partially Upgraded</span>
                      </label>
                      <label className="radio-item">
                        <input
                          onChange={e => onChangeValue(e)}
                          type="radio"
                          checked={upgradeFlooring === 2}
                          value="2"
                          name="upgrade-flooring"
                        />{" "}
                        <span>Full Upgraded</span>
                      </label>
                      <label className="radio-item">
                        <input
                          onChange={e => onChangeValue(e)}
                          type="radio"
                          checked={upgradeFlooring === 0}
                          value="0"
                          name="upgrade-flooring"
                        />{" "}
                        <span>None</span>
                      </label>
                    </div>
                  </div>
                </div>
                {propertyType === 8 || propertyType === 10 ? (
                  <>
                    <div className="radio-bk">
                      {" "}
                      <div className="icon-bk swimming"></div>
                      <div className="option-bk">
                        <p className="title">Swimming Pool:</p>

                        <div className="d-md-flex d-block">
                          <label className="radio-item">
                            <input
                              onChange={e => onChangeValue(e)}
                              type="radio"
                              checked={upgradePool === 1}
                              value="1"
                              name="upgrade-pool"
                            />{" "}
                            <span>Partially Upgraded</span>
                          </label>
                          <label className="radio-item">
                            <input
                              onChange={e => onChangeValue(e)}
                              type="radio"
                              checked={upgradePool === 2}
                              value="2"
                              name="upgrade-pool"
                            />{" "}
                            <span>Full Upgraded</span>
                          </label>
                          <label className="radio-item">
                            <input
                              onChange={e => onChangeValue(e)}
                              type="radio"
                              checked={upgradePool === 0}
                              value="0"
                              name="upgrade-pool"
                            />{" "}
                            <span>None</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="radio-bk">
                      {" "}
                      <div className="icon-bk garden"></div>
                      <div className="option-bk">
                        <p className="title">Landscape Garden:</p>

                        <div className="d-md-flex d-block">
                          <label className="radio-item">
                            <input
                              onChange={e => onChangeValue(e)}
                              type="radio"
                              checked={upgradeGarden === 1}
                              value="1"
                              name="upgrade-garden"
                            />{" "}
                            <span>Partially Upgraded</span>
                          </label>
                          <label className="radio-item">
                            <input
                              onChange={e => onChangeValue(e)}
                              type="radio"
                              checked={upgradeGarden === 2}
                              value="2"
                              name="upgrade-garden"
                            />{" "}
                            <span>Full Upgraded</span>
                          </label>
                          <label className="radio-item">
                            <input
                              onChange={e => onChangeValue(e)}
                              type="radio"
                              checked={upgradeGarden === 0}
                              value="0"
                              name="upgrade-garden"
                            />{" "}
                            <span>None</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Button
            className="button button-filled-green"
            onClick={() => stepTwo()}
          >
            confirm details
          </Button>
        </div>
      ) : step === 2 && confidenceLevel !== "N/A" ? (
        <div>
          <h4>Name *</h4>
          <Form.Control
            required={false}
            type={"text"}
            name={"name"}
            value={name}
            // placeholder={"Enter Name"}
            onChange={handlechange}
            autocomplete="off"
            className={nameError && stepThreeValidate ? "error" : ""}
          />
          {nameError && stepThreeValidate && (
            <div className="errortxt">Please Enter Name</div>
          )}
          <h4>Email *</h4>
          <Form.Control
            required={false}
            type={"email"}
            name={"email"}
            value={email}
            // placeholder={"Enter Email"}
            onChange={handlechange}
            autocomplete="off"
            className={
              (emailError && stepThreeValidate) ||
              (!emailError && emailFormatError && stepThreeValidate)
                ? "error"
                : ""
            }
          />
          {emailError && stepThreeValidate && (
            <div className="errortxt">Please Enter Email</div>
          )}
          {!emailError && emailFormatError && stepThreeValidate && (
            <div className="errortxt">Please Enter Valid Email</div>
          )}
          <h4>Phone *</h4>
          <PhoneInput
            international
            placeholder="Telephone"
            defaultCountry="AE"
            //   placeholder="Enter phone number"
            value={phone}
            className={phoneError && stepThreeValidate ? "error" : ""}
            onChange={phoneChange}
          />
          {phoneError && stepThreeValidate && (
            <div className="errortxt">Please Enter Phone Number</div>
          )}
          {otpOn && (
            <>
              <h4>Please enter the OTP *</h4>
              <Form.Control
                required={false}
                type={"number"}
                name={"otp"}
                value={otp}
                // placeholder={"Enter your OTP here..."}
                onChange={handlechange}
                autocomplete="off"
                className={otpError && stepThreeValidate ? "error" : ""}
              />
            </>
          )}
          {otpError && <div className="errortxt">{otpError}</div>}

          <ReCaptchaBox
            fieldClass="py-2 captcha-class"
            captRef={recaptchaRef}
            key={1}
            handleonVerify={handleonVerify}
            className={""}
          />

          <div className="otp-container">
            <Button
              className="button gen-opt-btn"
              onClick={() => generateOtp()}
            >
              {otpOn ? "Re-Generate OTP" : "Generate OTP"}
            </Button>
            <span>{`(By clicking ${
              otpOn ? "Re-Generate OTP" : "Generate OTP"
            } you will receive a code from property monitor)`}</span>
          </div>

          {!otpOn && stepThreeValidate && (
            <div className="errortxt">Please Generate OTP</div>
          )}
          <Button
            className="button button-filled-green"
            onClick={() => stepThree()}
          >
            get your valuation
          </Button>
          <p className="terms-para">
            By clicking Submit, I accept{" "}
            <Link to="/terms-and-conditions/">terms and conditions</Link> &{" "}
            <Link to="/privacy-policy/">Privacy Policy</Link>.
          </p>
        </div>
      ) : confidenceLevel !== "N/A" ? (
        <div className="report-bk">
          <ValuationResult valuation={valuation} />
          <h4 className="report-tit">Want to take the next step?</h4>
          <p className="report-para">
            Your report provides great insights into the potential value of your
            property. Every home is unique, so to further enhance the process
            it’s helpful to have an expert consultant review the data with you.
            Plus, some professional advice before selling your property can go a
            long way.
          </p>
          <a
            href="javascript:void(0)"
            className="button button-filled-green"
            onClick={() => handleModal()}
          >
            book your appointment
          </a>
          {
            <Modal
              show={show}
              // onHide={handleClose}
              backdrop="static"
              centered
              dialogClassName="modal-popup-form"
            >
              <Modal.Body>
                <div className="popup-form-wrapper">
                  <div className="close-modal" onClick={() => handleClose()}>
                    <i className="icon black-cancel-icon"></i>
                  </div>
                  <div>
                    <DefaultForm
                      fields={FormFields}
                      sourceUrl={pageurl}
                      formTitle="Book an Appointment"
                      reportUrl={valuation?.reportUrl}
                    />
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          }
        </div>
      ) : null}
      {confidenceLevel === "N/A" ? (
        <>
          <div className="error-block">{confidenceMessage}</div>
          <p className="note-txt">
          If you cannot find your property,{" "}
          <Link to="/contact/">get in touch</Link> and our team will be happy to
          help.
        </p>
          {/* <Button
            className="button button-filled-green"
            onClick={() => tryNew()}
          >
            Try another address
          </Button> */}
        </>
      ) : null}
      {step === 0 && (
        <p className="note-txt">
          If you cannot find your property,{" "}
          <Link to="/contact/">get in touch</Link> and our team will be happy to
          help.
        </p>
      )}
    </div>
  )
}

export default PropertyMonitor
